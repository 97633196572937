.HomeSubsection {
  margin-top: calc(var(--vertical-rhythm-unit) * 3);

  @media #{$mq-md} {
    margin-top: calc(var(--vertical-rhythm-unit) * 5);
  }
}

.HomeSubsection__heading {
  font-family: $font-family-gothic-bold;
  font-weight: $font-weight-gothic-bold;
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  margin-top: 0;
  margin-bottom: calc(0px - var(--vertical-rhythm-unit));
}

.HomeSubsection::before {
  content: "";
  display: block;
  height: var(--vertical-rhythm-unit);
  background-image: linear-gradient($color-gray-300, $color-gray-300);
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
}

.HomeSubsection__readMore {
  margin-top: calc(var(--vertical-rhythm-unit) * 2);
  margin-left: 2rem;

  @media #{$mq-sm} {
    margin-left: percentage(144 / 720);
  }

  @media #{$mq-md} {
    margin-left: percentage(240 / 960);
    margin-right: percentage(80 / 960);
  }
}
