.Member {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Member__header {
  flex-grow: 2;
  display: grid;
  grid-template-areas: "avator name" "avator jobTitle";
  grid-template-columns: auto 1fr;
  column-gap: 1rem;
  align-items: center;
}

.Member__avator {
  grid-area: avator;
  margin-top: 0;
}

.Member__avatorImage {
  width: 3rem;
  box-shadow: 0 0 0 1px $color-gray-300;
  border-radius: 50%;
}

.Member__name {
  grid-area: name;
  margin-top: auto;
  font-size: 1rem;
  line-height: inherit;
  font-feature-settings: normal;
}

.Member__jobTitle {
  grid-area: jobTitle;
  margin-top: 0;
  margin-bottom: auto;
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  color: $color-gray-700;
}

.Member__lede {
  margin-top: calc(var(--extra-leading) * 2);
  // font-size: var(--font-size-caption);
}

.Member__networks {
  flex-grow: 3;
  display: flex;
  align-items: flex-end;
  // margin-top: calc(var(--extra-leading) * 2);
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
}

.Member__networkList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  margin-right: -1em;
  margin-bottom: calc(var(--extra-leading) * -2);
  list-style-type: none;
}

.Member__networkItem {
  margin-right: 1em;
}

.Member__networkLink {
  display: block;
  padding-top: calc(var(--extra-leading) * 2);
  padding-bottom: calc(var(--extra-leading) * 2);
}
