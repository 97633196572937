.BlogTeaser {
  position: relative;
  height: 100%;
}

.BlogTeaser__date {
  margin-top: 0;
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  color: $color-gray-700;
  padding-top: var(--extra-leading);
  padding-bottom: var(--extra-leading);
}

.BlogTeaser__title {
  font-size: var(--font-size-body);
  line-height: var(--line-height-body);
  margin-top: 0;
  font-feature-settings: normal;
  font-kerning: none;
}

.BlogTeaser__titleLink {
}

.BlogTeaser__titleLink:active {
  background-color: transparent;
}

.BlogTeaser__titleLink::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.BlogTeaser__titleLink:active::before {
  background-color: var(--color-active-highlight);
}

.BlogTeaser__lede {
  margin-top: 0;
  font-size: var(--font-size-body);
  line-height: var(--line-height-body);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.BlogTeaser__lede:lang(en) {
  font-family: $font-family-sans;
}
