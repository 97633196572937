.Permalink {
  display: inline-block;
  position: relative;
  font-size: 1em;
  line-height: 1;
  margin-left: 0.75rem;
  color: $color-gray-400;
}

.Permalink::before {
  content: "";
  position: absolute;
  top: -0.75rem;
  bottom: -0.75rem;
  left: -0.75rem;
  right: -0.75rem;
}

.Permalink:hover,
.Permalink:focus,
h2:hover > .Permalink,
h3:hover > .Permalink,
h4:hover > .Permalink {
  color: $color-blue;
}

.Permalink__icon {
  width: 1.25rem;
  height: 1.25rem;
}
