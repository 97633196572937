.Surface {
  --color-link: #{$color-blue};
  --color-link-underline: #{$color-blue};
  --color-active-highlight: #{rgba(black, 0.2)};
  --color-focus-ring: #{rgba($color-blue, 0.5)};
  display: grid;
  color: $color-black;
  background-color: $color-white;
}

.Surface__inner {
  min-width: 0;
}

.Surface__inner::after {
  content: "";
  display: block;
  margin-top: calc(var(--vertical-rhythm-unit) * 5);

  @media #{$mq-md} {
    margin-top: calc(var(--vertical-rhythm-unit) * 8);
  }
}
