.ArticleHeader header {
  margin-top: calc(var(--vertical-rhythm-unit) * 5);
  margin-bottom: calc(var(--vertical-rhythm-unit) * 3);

  @media #{$mq-sm} {
    margin-right: percentage(144 / 720);
  }

  @media #{$mq-md} {
    margin-top: calc(var(--vertical-rhythm-unit) * 8);
    margin-bottom: calc(var(--vertical-rhythm-unit) * 5);
    // margin-left: calc(25% + 0.75rem);
    margin-right: percentage(320 / 960);
  }
}

.ArticleHeader footer {
  margin-left: 2rem;
  // padding-right: calc(var(--grid-gutter) / 2);
  // padding-left: calc(var(--grid-gutter) / 2);

  @media #{$mq-sm} {
    margin-left: percentage(144 / 720);
  }

  @media #{$mq-md} {
    margin-left: percentage(240 / 960);
    margin-right: percentage(80 / 960);
  }
}

.ArticleHeader__category {
  margin-top: 0;
  color: $color-gray-700;
}

.ArticleHeader__date {
  margin-top: 0;
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  color: $color-gray-700;
  text-align: right;
}

.ArticleHeader__date::before {
  content: "";
  display: block;
  height: var(--vertical-rhythm-unit);
  background-image: linear-gradient($color-gray-300, $color-gray-300);
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  // margin-bottom: calc(var(--vertical-rhythm-unit) * 2);
}

.ArticleHeader__authorList {
  display: grid;
  row-gap: calc(var(--vertical-rhythm-unit) / 2);
  margin-top: calc(var(--vertical-rhythm-unit) * 2);
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
}

.ArticleHeader__authorItem {
  display: grid;
  grid-template-areas: "avator name" "avator jobTitle";
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  align-items: center;
}

.ArticleHeader__authorAvator {
  grid-area: avator;
  margin-top: 0;
}

.ArticleHeader__authorAvatorImage {
  width: 2.5rem;
  box-shadow: 0 0 0 1px $color-gray-300;
  border-radius: 50%;
}

.ArticleHeader__authorName {
  grid-area: name;
  margin-top: auto;
}

.ArticleHeader__authorJobTitle {
  grid-area: jobTitle;
  margin-top: 0;
  margin-bottom: auto;
  color: $color-gray-700;
}
