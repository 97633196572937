:root {
  --color-link: #{$color-white};
  --color-link-underline: #{rgba($color-white, 0.4)};
  --color-active-highlight: #{rgba(white, 0.2)};
  --color-focus-ring: #{rgba(mix($color-blue, $color-white), 0.8)};

  --container-width: 32rem;
  --grid-columns: 4;
  --grid-gutter: 2rem;
  --grid-margin: 1rem;

  --font-family-body: #{$font-family-gothic};
  --font-family-body-bold: #{$font-family-gothic-bold};
  --font-family-h1: #{$font-family-gothic-bold};
  --font-family-h2: #{$font-family-gothic-thin};
  --font-family-h3: #{$font-family-gothic-bold};
  --font-family-mono: #{$font-family-mono};

  --font-size-body: 1rem;
  --font-size-h1: #{1rem * 8 / 5};
  --font-size-h2: #{1rem * 8 / 5};
  --font-size-h3: #{1rem * 8 / 7};
  --font-size-h4: #{1rem * 8 / 9};
  --font-size-display: #{1rem * 8 / 8};
  --font-size-caption: #{1rem * 8 / 10};

  --line-height-body: #{1em * 7 / 4};
  --line-height-h1: #{1em * 5 / 4};
  --line-height-h2: #{1em * 5 / 4};
  --line-height-h3: #{1em * 49 / 32};
  --line-height-display: 2em;
  --line-height-caption: #{1em * 25 / 16};

  --vertical-rhythm-unit: 1.75rem;
  --extra-leading: 0.25rem;

  @media #{$mq-sm} {
    --container-width: calc(#{$bp-sm} - var(--grid-margin) * 2);
    --grid-columns: 8;
    --grid-gutter: 3rem;
    --grid-margin: 1.5rem;

    --font-size-h1: #{1rem * 8 / 4};
    --font-size-h2: #{1rem * 8 / 4};
    --font-size-display: #{1rem * 8 / 7};

    --line-height-display: #{1rem * 63 / 32};
  }

  @media #{$mq-md} {
    --container-width: calc(#{$bp-md} - var(--grid-margin) * 2);
    --grid-columns: 12;
    --grid-margin: 2rem;
  }

  // @media #{$mq-fhd-width} and #{$mq-fhd-height} {
  //   font-size: percentage(1.125);
  // }
}

:lang(en) {
  --font-family-body: #{$font-family-sans};
  --font-family-h1: #{$font-family-sans-bold};
  --font-family-h2: #{$font-family-sans-thin};
  --font-family-h3: #{$font-family-sans-bold};
  --font-family-mono: #{$font-family-mono-en};

  --font-family-body-bold: #{$font-family-sans-bold};
  --font-family-body-italic: #{$font-family-sans-italic};
  --font-family-h1-italic: #{$font-family-sans-bolditalic};
  --font-family-h2-italic: #{$font-family-sans-thinitalic};
  --font-family-h3-italic: #{$font-family-sans-bolditalic};

  --font-size-body: 1.09375rem;
  --font-size-h1: #{1.09375rem * 8 / 5};
  --font-size-h2: #{1.09375rem * 8 / 5};
  --font-size-h3: #{1.09375rem * 8 / 7};

  --line-height-body: #{1em * 8 / 5};
  --line-height-h1: #{1em * 8 / 7};
  --line-height-h2: #{1em * 8 / 7};
  --line-height-h3: #{1em * 7 / 5};

  @media #{$mq-sm} {
    --font-size-h1: #{1.09375rem * 8 / 4};
    --font-size-h2: #{1.09375rem * 8 / 4};
  }
}

html {
  --font-family-bold: var(--font-family-body-bold);
  font-family: var(--font-family-body);
  font-weight: 300;
  line-height: 1.75;
  font-kerning: none;
  line-break: normal; // for Firefox
  overflow-wrap: break-word;
  text-underline-position: under;
  // text-underline-offset: 0.1em;
  // text-decoration-thickness: 0.05em;
  hanging-punctuation: allow-end;
}

:lang(en) {
  font-kerning: auto;
}

body {
  color: $color-white;
  background-color: $color-gray-900;
}

h1 {
  --font-family-italic: var(--font-family-h1-italic);
  margin-top: 0;
  margin-bottom: 0;
  font-family: var(--font-family-h1);
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
  font-feature-settings: "palt";
  font-kerning: auto;
}

h2 {
  --font-family-italic: var(--font-family-h2-italic);
  margin-top: calc(var(--vertical-rhythm-unit) * 3);
  margin-bottom: calc(var(--vertical-rhythm-unit) * 2);
  font-family: var(--font-family-h2);
  font-weight: $font-weight-gothic-thin;
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
  font-feature-settings: "palt";
  font-kerning: auto;

  @media #{$mq-sm} {
    --Permalink-margin-top: -0.1875em;
    --Permalink-margin-bottom: -0.1875em;
  }
}

h3 {
  --font-family-italic: var(--font-family-h3-italic);
  margin-top: calc(var(--vertical-rhythm-unit) * 2);
  margin-bottom: 0;
  font-family: var(--font-family-h3);
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  font-feature-settings: "palt";
  font-kerning: auto;
}

h4 {
  margin-top: var(--vertical-rhythm-unit);
  margin-bottom: 0;
  font-family: $font-family-gothic-bold;
  font-size: var(--font-size-h4);
  line-height: 1.5;
}

p,
li,
dd,
figcaption {
  hyphens: auto;
}

p {
  margin-top: var(--vertical-rhythm-unit);
  margin-bottom: 0;
}

h4 + p {
  margin-top: (1.75rem / 2);
}

hr {
  color: inherit;
  height: auto;
}

pre {
  overflow: auto;
  overflow-wrap: normal;
  margin: calc(var(--vertical-rhythm-unit) * 2 - var(--line-height-caption)) 0;
  padding: var(--line-height-caption) 1rem;
  font-family: var(--font-family-mono);
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  background-color: $color-gray-100;
}

blockquote {
  margin: calc(var(--vertical-rhythm-unit) * 2) 0;
}

ol,
ul {
  margin-top: 1.75rem;
  margin-bottom: 0;
  padding-left: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-top: 0;
  padding-left: 2rem;
}

dl {
  margin-top: var(--vertical-rhythm-unit);
  margin-bottom: 0;
}

dt {
  font-family: $font-family-gothic-bold;
  font-weight: bold;
}

dd {
  margin-left: 2rem;
}

figure {
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  margin: calc(var(--vertical-rhythm-unit) * 3 - var(--line-height-caption)) 0;
}

figcaption {
  margin-top: calc(var(--vertical-rhythm-unit) / 2);
  color: $color-gray-700;
}

a {
  color: var(--color-link);
  text-decoration-color: var(--color-link-underline);
}

a:focus {
  outline: 3px solid var(--color-focus-ring);
  outline-offset: 1px;
}

a:active {
  background-color: var(--color-active-highlight);
}

strong,
b,
em,
cite,
dfn,
var,
i,
code,
kbd,
samp {
  line-height: 0;
}

em:lang(ja),
strong,
b {
  font-family: var(--font-family-bold, inherit);
  font-weight: bold;
}

em:lang(ja) {
  @supports (text-emphasis: dot) {
    font-family: "em-dot", var(--font-family-body);
    font-weight: inherit;
    text-emphasis: dot;
  }
}

strong em:lang(ja),
b em:lang(ja) {
  @supports (text-emphasis: dot) {
    font-family: "em-dot", var(--font-family-body-bold);
  }
}

@font-face {
  font-family: "em-dot";
  src: local("Times"), local("Times New Roman");
  unicode-range: U+2022;
}

address,
em,
cite,
dfn,
var,
i {
  font-family: var(--font-family-italic, inherit);
  font-style: inherit;
}

code,
kbd,
samp {
  font-family: var(--font-family-mono);
  hyphens: none;
}

:not(pre) > code:lang(en),
:not(pre) > kbd:lang(en),
:not(pre) > samp:lang(en) {
  font-size-adjust: $font-size-aspect-sans;

  @supports not (font-size-adjust: 1) {
    font-size: 90%;
  }
}

img,
svg,
video {
  vertical-align: bottom;
}

img,
video {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  max-width: 100%; // hack for Safari
  max-height: 100%; // hack for Safari
  fill: currentcolor;
}

table {
  display: block;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: calc(var(--vertical-rhythm-unit) * 2);
  margin-bottom: calc(var(--vertical-rhythm-unit) * 2);
  font-size: var(--font-size-caption);
  line-height: 1.5;
  border-collapse: collapse;
}

th {
  font-family: $font-family-gothic-bold;
  text-align: left;
}

th,
td {
  padding: 1.5em 2rem 1.5em 0;
  white-space: nowrap;
}

th:first-child,
td:first-child {
  padding-left: 2rem;
}

thead th,
thead td {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

tbody th,
tbody td {
  border-bottom: 1px solid $color-gray-400;
}

tbody tr:first-child th,
tbody tr:first-child td {
  border-top: 1px solid;
}

input,
button,
textarea {
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
