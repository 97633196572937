.VisuallyHidden {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  white-space: nowrap !important;
  clip-path: inset(50%) !important;
  clip: rect(0, 0, 0, 0) !important; // deprecated
}
