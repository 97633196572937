.Button {
  display: inline-block;
  border: 1px solid;
  padding-left: calc(1rem - 1px);
  padding-right: calc(1rem - 1px);
  font-size: var(--font-size-caption);
  font-size: calc(1rem * 8 / 9);
  line-height: calc(2.5rem - 2px);
  text-decoration: none;
}

.Button__label {
  font-family: $font-family-gothic-bold;
}

.Button__label + .Button__icon {
  margin-left: 0.25rem;
}
