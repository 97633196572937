.SiteHeader {
  --color-link: #{$color-black};
  --color-link-underline: #{rgba($color-black, 0.5)};
  color: $color-black;
  background-color: $color-white;

  @media #{$mq-sm} {
    @include container;
  }
}

.SiteHeader__nav {
  padding-top: var(--vertical-rhythm-unit);
  @media #{$mq-sm} {
    padding-top: var(--vertical-rhythm-unit);
    // box-shadow: inset 0 -1px $color-gray-300;
  }
}

.SiteHeader__navList {
  @media #{$mq-sm} {
    display: flex;
    align-items: flex-end;
  }
}

.SiteHeader__homeItem {
  padding-bottom: 0.5rem;
  @media not all and #{$mq-sm} {
    @include container;
    padding-top: 0.5rem;
  }
}

.SiteHeader__homeLink {
  display: block;
  width: fit-content;
}

.SiteHeader__homeImage {
  height: 2rem;
  padding: 0.5rem;
}

.SiteHeader__subList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  white-space: nowrap;
  font-size: var(--font-size-caption);
  line-height: 2rem;
  // background-color: $color-gray-100;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  @media #{$mq-sm} {
    margin-left: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.SiteHeader__subListInner {
}

.SiteHeader__subItem {
  margin-left: 0.375rem;
  margin-right: 0.375rem;

  @media #{$mq-sm} {
    margin-left: 0.5rem;
    margin-right: 0;
  }
}

.SiteHeader__subLink {
  display: block;
  text-decoration: none;
  background-image: none;
  padding-left: 0.25rem;
  padding-right: 0.25rem;

  @media #{$mq-sm} {
    padding: 0.5rem;
  }
}

.SiteHeader__subLink[aria-current="location"] {
  font-family: $font-family-gothic-bold;
  font-weight: bold;
  // box-shadow: inset 0 -2px $color-gray-700;
}

.SiteHeader__subLinkInner {
  display: inline-block;
  line-height: 1.4;
  border-bottom: 1px solid $color-gray-700;
}

.SiteHeader__subLink[aria-current="location"] .SiteHeader__subLinkInner {
  border-bottom: 2px solid $color-gray-900;
  // line-height: calc(1.4em - 2px);
}
