.SiteFooter {
  @include container;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  column-gap: var(--grid-gutter);
  row-gap: var(--vertical-rhythm-unit);
  grid-template-areas:
    "siteLogo siteLogo siteLogo siteLogo"
    "primaryList primaryList secondaryList secondaryList"
    "companyLogo companyLogo companyLogo companyLogo";
  padding-top: var(--vertical-rhythm-unit);

  @media #{$mq-sm} {
    grid-template-areas:
      "siteLogo siteLogo siteLogo siteLogo primaryList primaryList secondaryList secondaryList"
      "companyLogo companyLogo companyLogo companyLogo companyLogo companyLogo companyLogo companyLogo";
  }

  @media #{$mq-md} {
    grid-template-areas:
      "siteLogo siteLogo siteLogo siteLogo siteLogo siteLogo primaryList primaryList primaryList secondaryList secondaryList secondaryList"
      "companyLogo companyLogo companyLogo companyLogo companyLogo companyLogo companyLogo companyLogo companyLogo companyLogo companyLogo companyLogo";
  }
}

.SiteFooter__siteLogo {
  grid-area: siteLogo;
  margin-top: 0;
}

.SiteFooter__siteLogoLink {
  display: block;
  width: fit-content;
}

.SiteFooter__siteLogoImage {
  padding-top: var(--extra-leading);
  padding-bottom: var(--extra-leading);
  height: var(--vertical-rhythm-unit);
}

.SiteFooter__navList {
  margin-top: 0;
  list-style-type: none;
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
}

.SiteFooter__navList.-primary {
  grid-area: primaryList;
}

.SiteFooter__navList.-secondary {
  grid-area: secondaryList;
}

.SiteFooter__navItem {
}

.SiteFooter__navLink {
  display: block;
  width: fit-content;
  padding-top: calc(var(--extra-leading) * 2);
  padding-bottom: calc(var(--extra-leading) * 2);
  text-decoration: none;
}

.SiteFooter__navLinkInner {
  border-bottom: 1px solid var(--color-link-underline);
}

.SiteFooter__companyLogo {
  grid-area: companyLogo;
  margin-top: 0;
  padding-top: (1.75rem / 4);
  padding-bottom: (1.75rem / 4);
  box-shadow: 0 -1px $color-gray-700;
}

.SiteFooter__companyLogoLink {
  display: block;
  width: fit-content;
  margin-left: auto;
}

.SiteFooter__companyLogoImage {
  padding-top: (1.75rem / 3 + 1.75rem / 4);
  padding-bottom: (1.75rem / 3 + 1.75rem / 4);
  height: (1.75em / 3);
}
