.Contact {
  --color-link: #{$color-blue};
  --color-link-underline: #{$color-blue};
  --color-active-highlight: #{rgba(black, 0.2)};
  --color-focus-ring: #{rgba($color-blue, 0.5)};
  background-color: $color-gray-100;
  color: $color-black;
}

.Contact__inner {
  @include container;
  padding-top: calc(var(--vertical-rhythm-unit) * 2);
  padding-bottom: calc(var(--vertical-rhythm-unit) * 3);
}

.Contact__heading {
  font-family: $font-family-gothic-bold;
  font-weight: $font-weight-gothic-bold;
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
  margin-top: calc(var(--vertical-rhythm-unit) * 2);
  margin-bottom: var(--vertical-rhythm-unit);
}

.Contact__heading::before {
  // content: "";
  display: block;
  height: var(--vertical-rhythm-unit);
  background-image: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12));
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
}

.Contact__body {
  margin-left: 2rem;

  @media #{$mq-sm} {
    margin-left: percentage(144 / 720);
  }

  @media #{$mq-md} {
    margin-left: percentage(240 / 960);
    margin-right: percentage(80 / 960);
  }
}
.Contact__inner > :first-child,
.Contact__body > :first-child {
  margin-top: 0;
}
