.MemberList {
  margin-left: 2rem;

  @media #{$mq-sm} {
    margin-left: percentage(144 / 720);
  }

  @media #{$mq-md} {
    margin-left: percentage(240 / 960);
    margin-right: percentage(80 / 960);
  }
}

.MemberList__item {
  @media #{$mq-sm} {
    position: relative;
  }
}

.MemberList__item {
  margin-top: calc(var(--vertical-rhythm-unit) * 2);
}
