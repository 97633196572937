.CodeCaption {
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  margin-top: calc(
    var(--vertical-rhythm-unit) * 2 - var(--line-height-caption)
  );
  margin-bottom: 0.5rem;
  color: $color-gray-700;
}

.CodeCaption + pre {
  margin-top: 0;
}
