// Color
$color-white: hsl(0, 0%, 100%);
$color-black: hsl(0, 0%, 0%);
$color-gray-100: hsl(0, 0%, 96%);
$color-gray-300: hsl(0, 0%, 88%);
$color-gray-400: hsl(0, 0%, 74%);
$color-gray-700: hsl(0, 0%, 38%);
$color-gray-900: hsl(0, 0%, 13%);
$color-blue: hsl(223, 56%, 51%);

// Media query
$bp-sm: 48rem;
$bp-md: 64rem;
$bp-fhd-width: (1920 / 16 * 1rem);
$bp-fhd-height: (1080 / 16 * 1rem);

// Use em unit for bugs in Safari.
// https://zellwk.com/blog/media-query-units/
$mq-sm: "(min-width: #{$bp-sm / 1rem * 1em})";
$mq-md: "(min-width: #{$bp-md / 1rem * 1em})";
$mq-fhd-width: "(min-width: #{$bp-fhd-width / 1rem * 1em})";
$mq-fhd-height: "(min-height: #{$bp-fhd-height / 1rem * 1em})";

// Layout
@mixin container {
  max-width: var(--container-width);
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--grid-margin);
  padding-left: var(--grid-margin);
}

// Typgraphy
$font-family-gothic: "TazuganeGothicStdN-Light", sans-serif;
$font-family-gothic-bold: "TazuganeGothicStdN-Medium", sans-serif;
$font-family-gothic-thin: "TazuganeGothicStdN-Thin", sans-serif;
$font-family-mono: "Menlo", "Consolas", "TazuganeGothicStdN-Light", monospace;
$font-family-mono-en: "Menlo", "Consolas", "FrutigerNeueLTW1G-Light", monospace;

$font-family-sans: "FrutigerNeueLTW1G-Light", sans-serif;
$font-family-sans-italic: "FrutigerNeueLTW1G-LightIt", sans-serif;
$font-family-sans-bold: "FrutigerNeueLTW1G-Medium", sans-serif;
$font-family-sans-bolditalic: "FrutigerNeueLTW1G-MediumIt", sans-serif;
$font-family-sans-thin: "FrutigerNeueLTW1G-Thin", sans-serif;
$font-family-sans-thinitalic: "FrutigerNeueLTW1G-ThinIt", sans-serif;

$font-weight-gothic-bold: 700;
$font-weight-gothic-thin: 200;

$font-size-aspect-sans: 0.508;
