.HomeTagline {
  margin-top: calc(var(--vertical-rhythm-unit) * 5);

  @media #{$mq-md} {
    margin-top: calc(var(--vertical-rhythm-unit) * 8);
  }
}

.HomeTagline__title {
  margin-top: 0;
}

.HomeTagline__titleImage {
  width: 18rem;

  @media #{$mq-sm} {
    width: 100%;
  }

  @media #{$mq-md} {
    width: 46rem;
  }
}

.HomeTagline__lede {
  font-size: var(--font-size-display);
  line-height: var(--line-height-display);
  margin-top: calc(var(--vertical-rhythm-unit) * 2);

  @media #{$mq-md} {
    width: 46rem;
  }
}

.HomeTagline__ledeChunk {
  @media #{$mq-sm} {
    display: inline-block;
  }
}

.HomeTagline__readMore {
  margin-top: var(--vertical-rhythm-unit);
  margin-bottom: 0;
}
