.ArticleBody {
  --font-family-bold: var(--font-family-body-bold);
  --font-family-italic: var(--font-family-body-italic);
  font-family: var(--font-family-body, inherit);
  font-size: var(--font-size-body);
  line-height: var(--line-height-body);
  margin-top: calc(var(--vertical-rhythm-unit) * 2);
  margin-right: calc(var(--grid-gutter) / 2 * -1);
  margin-left: calc(var(--grid-gutter) / 2 * -1);
}

.ArticleBody aside {
  margin: calc(var(--vertical-rhythm-unit) * 2) calc(var(--grid-gutter) / 2)
    calc(var(--vertical-rhythm-unit) * 1.5) calc(var(--grid-gutter) / 2);
  padding: calc(1.875em - 1px) calc(1rem - 1px);
  font-size: var(--font-size-caption);
  line-height: 1.875;
  border: 1px dashed $color-gray-400;

  @media #{$mq-sm} {
    margin-left: calc(var(--grid-gutter) / 2 + #{percentage(144 / 768)});
    padding-right: calc(2rem - 1px);
    padding-left: calc(2rem - 1px);
  }

  @media #{$mq-md} {
    margin-left: calc(var(--grid-gutter) / 2 + #{percentage(240 / 1008)});
    margin-right: calc(var(--grid-gutter) / 2 + #{percentage(80 / 1008)});
  }
}

.ArticleBody aside > :first-child {
  margin-top: 0;
}

.ArticleBody aside > :last-child {
  margin-bottom: 0;
}

.ArticleBody h2 {
  padding-right: calc(var(--grid-gutter) / 2);
  padding-left: calc(var(--grid-gutter) / 2);

  @media #{$mq-sm} {
    margin-right: percentage(144 / 768);
  }

  @media #{$mq-md} {
    margin-right: percentage(320 / 1008);
  }
}

.ArticleBody h3,
.ArticleBody h4 {
  margin-left: 2rem;
  padding-right: calc(var(--grid-gutter) / 2);
  padding-left: calc(var(--grid-gutter) / 2);

  @media #{$mq-sm} {
    margin-left: percentage(144 / 768);
  }

  @media #{$mq-md} {
    margin-left: percentage(240 / 1008);
    margin-right: percentage(80 / 1008);
  }
}

.ArticleBody aside h3 {
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;

  @media #{$mq-sm} {
    margin-left: 0;
  }
}

.ArticleBody p,
.ArticleBody hr {
  margin-left: 2rem;
  padding-right: calc(var(--grid-gutter) / 2);
  padding-left: calc(var(--grid-gutter) / 2);

  @media #{$mq-sm} {
    margin-left: percentage(144 / 768);
  }

  @media #{$mq-md} {
    margin-left: percentage(240 / 1008);
    margin-right: percentage(80 / 1008);
  }
}

.ArticleBody aside p {
  margin-top: 1.875em;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;

  @media #{$mq-md} {
    margin-right: 0;
  }
}

.ArticleBody blockquote p {
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.ArticleBody p[role="note"] {
  margin: calc(var(--vertical-rhythm-unit) * 2 - var(--line-height-caption))
    calc(var(--grid-gutter) / 2)
    calc(var(--vertical-rhythm-unit) * 2 - var(--line-height-caption))
    calc(var(--grid-gutter) / 2 + 2rem);
  padding: calc(var(--line-height-caption) - 1px) 0;
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  color: $color-gray-700;
  border-top: 1px dotted $color-gray-400;
  border-bottom: 1px dotted $color-gray-400;

  @media #{$mq-sm} {
    margin-left: calc(var(--grid-gutter) / 2 + #{percentage(144 / 768)});
  }

  @media #{$mq-md} {
    margin-left: calc(var(--grid-gutter) / 2 + #{percentage(240 / 1008)});
    margin-right: calc(var(--grid-gutter) / 2 + #{percentage(80 / 1008)});
  }
}

.ArticleBody hr {
  border: 0;
  text-align: center;
  margin-top: var(--vertical-rhythm-unit);
  margin-bottom: var(--vertical-rhythm-unit);
}

.ArticleBody hr::before {
  content: "＊";
}

.ArticleBody .CodeCaption,
.ArticleBody pre {
  @media #{$mq-sm} {
    margin-right: calc(var(--grid-gutter) / 2);
    margin-left: calc(var(--grid-gutter) / 2);
  }

  @media #{$mq-md} {
    margin-left: calc(var(--grid-gutter) / 2 + #{percentage(240 / 1008)});
  }
}

.ArticleBody .CodeCaption {
  @media #{$mq-sm} {
    padding-right: 0;
    padding-left: 0;
  }
}

.ArticleBody pre {
  padding-right: calc(var(--grid-gutter) / 2);
  padding-left: calc(var(--grid-gutter) / 2);

  @media #{$mq-sm} {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.ArticleBody aside pre {
  @media #{$mq-sm} {
    margin-right: 0;
    margin-left: 0;
  }

  @media #{$mq-md} {
    margin-left: 0;
  }
}

.ArticleBody blockquote {
  margin-left: 2rem;
  padding-right: calc(var(--grid-gutter) / 2);
  padding-left: calc(var(--grid-gutter) / 2);

  @media #{$mq-sm} {
    margin-left: calc(#{percentage(144 / 768)} + 2rem);
  }

  @media #{$mq-md} {
    margin-left: calc(#{percentage(240 / 1008)} + 2rem);
  }
}

.ArticleBody blockquote blockquote {
  margin-top: var(--vertical-rhythm-unit);
  margin-bottom: 0;

  @media #{$mq-sm} {
    margin-left: 2rem;
    padding-right: 0;
    padding-left: 0;
  }
}

.ArticleBody blockquote p {
  @media #{$mq-md} {
    margin-right: 0;
  }
}

.ArticleBody ol,
.ArticleBody ul {
  margin-left: 2rem;
  padding-right: calc(var(--grid-gutter) / 2);
  padding-left: calc(var(--grid-gutter) / 2);

  @media #{$mq-sm} {
    margin-left: percentage(144 / 768);
  }

  @media #{$mq-md} {
    margin-left: percentage(240 / 1008);
    margin-right: percentage(80 / 1008);
  }
}

.ArticleBody ol ol,
.ArticleBody ol ul,
.ArticleBody ul ol,
.ArticleBody ul ul,
.ArticleBody aside ol,
.ArticleBody aside ul {
  padding-right: 0;
  padding-left: 0;

  @media #{$mq-sm} {
    margin-left: 2rem;
  }
}

.ArticleBody aside ol,
.ArticleBody aside ul {
  @media #{$mq-md} {
    margin-right: 0;
  }
}

.ArticleBody dl {
  margin-left: 2rem;
  padding-right: calc(var(--grid-gutter) / 2);
  padding-left: calc(var(--grid-gutter) / 2);

  @media #{$mq-sm} {
    margin-left: percentage(144 / 768);
  }

  @media #{$mq-md} {
    margin-left: percentage(240 / 1008);
  }
}

.ArticleBody dt {
}

.ArticleBody dd {
}

.ArticleBody figure {
  padding-right: calc(var(--grid-gutter) / 2);
  padding-left: calc(var(--grid-gutter) / 2);

  @media #{$mq-sm} {
    margin-left: percentage(144 / 768);
  }

  @media #{$mq-md} {
    margin-left: percentage(240 / 1008);
  }
}

.ArticleBody figcaption {
}

.ArticleBody em {
}

.ArticleBody strong {
}

.ArticleBody small {
}

.ArticleBody img {
  margin-right: -1px;
  margin-left: -1px;
  border: 1px solid $color-gray-300;
}

.ArticleBody a img {
  margin-right: -3px;
  margin-left: -3px;
  border: 3px solid var(--color-link);
}

.ArticleBody table {
  padding-right: calc(var(--grid-gutter) / 2);
  padding-left: calc(var(--grid-gutter) / 2);

  @media #{$mq-sm} {
    margin-left: percentage(144 / 768);
  }

  @media #{$mq-md} {
    margin-left: percentage(240 / 1008);
  }
}

.ArticleBody aside table {
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
}

.ArticleBody .cp_embed_wrapper {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-right: calc(var(--grid-gutter) / 2);
  padding-left: calc(var(--grid-gutter) / 2);
}
