.CaseStudyIntro {
  display: grid;
  margin-top: calc(var(--vertical-rhythm-unit) * 2);
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  grid-template-areas: "cover cover cover cover" "meta meta meta meta";
  column-gap: var(--grid-gutter);
  row-gap: var(--vertical-rhythm-unit);

  @media #{$mq-sm} {
    grid-template-areas:
      "cover cover cover cover cover cover cover cover"
      ". . . . . meta meta meta";
  }

  @media #{$mq-md} {
    grid-template-areas: "cover cover cover cover cover cover cover cover meta meta meta meta";
  }
}

.CaseStudyIntro__cover {
  grid-area: cover;
  margin-top: 0;
  margin-bottom: 0;
}

.CaseStudyIntro__coverImage {
  width: 100%;
  box-shadow: 0 0 0 1px $color-gray-300;
}

.CaseStudyIntro__meta {
  grid-area: meta;
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
}

.CaseStudyIntro__metaList {
  margin-top: 0;
}

.CaseStudyIntro__metaKey {
  font-family: $font-family-gothic-bold;
  font-size: (1rem * 8 / 11);
  font-weight: bold;
  color: $color-gray-700;
}

* + .CaseStudyIntro__metaKey {
  margin-top: calc(var(--extra-leading) * 5);
}

.CaseStudyIntro__metaValue {
  margin-top: var(--extra-leading);
  margin-left: 0;
}
