.CaseStudyTeaser {
  position: relative;
}

.CaseStudyTeaser__thumbnail {
  margin-top: 0;
  // margin-left: -2rem;

  @media #{$mq-sm} {
    // margin-left: 0;
  }

  @media #{$mq-md} {
    margin-right: percentage(-80 / 640);
  }
}

.CaseStudyTeaser__thumbnailImage {
  width: 100%;
  box-shadow: 0 0 0 1px $color-gray-300;
}

// .CaseStudyTeaser__textBlock {
//   position: absolute;
//   left: -12rem;
//   top: 0;
//   width: 14rem;
// }

.CaseStudyTeaser__title {
  // font-size: calc(1rem * 8 / 3);
  // line-height: 1.125em;
  margin-top: calc(var(--vertical-rhythm-unit) * 0.5);
  font-size: var(--font-size-body);
  line-height: var(--line-height-body);
  font-feature-settings: normal;
  font-kerning: none;
}

.CaseStudyTeaser__titleLink {
}

.CaseStudyTeaser__titleLink:active {
  background-color: transparent;
}

.CaseStudyTeaser__titleLink::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.CaseStudyTeaser__titleLink:active::before {
  background-color: var(--color-active-highlight);
}

.CaseStudyTeaser__clientList {
  margin-top: 0;
  padding-top: var(--extra-leading);
  padding-bottom: var(--extra-leading);
  font-size: var(--font-size-caption);
  line-height: var(--line-height-caption);
  color: $color-gray-700;
}

.CaseStudyTeaser__clientItem {
  display: inline;
  margin-left: 0;
}

.CaseStudyTeaser__clientItem + .CaseStudyTeaser__clientItem::before {
  content: "/";
  margin-right: 0.5em;
  margin-left: 0.5em;
}
